<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 py-5">
            <div class="card-title">
              <h3 class="card-label">Devices Detailed Review</h3>
              <span class="d-block text-muted font-size-sm"
                ><span
                  class="label label-lg font-weight-bold label-light-primary label-inline"
                  >{{ devices.length }}</span
                ></span
              >
            </div>
            <div class="card-toolbar">
              <button @click="refresh" class="btn btn-light-primary mr-2">
                <span class="svg-icon svg-icon-md mr-0">
                  <inline-svg src="media/svg/icons/Media/Repeat.svg" />
                </span>
              </button>
              <button
                @click.prevent="changeTableView(tableView)"
                class="btn btn-light-primary font-weight-bold px-5 mr-3"
              >
                {{ tableViewButtonText }}
              </button>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div v-if="!isLoaded" class="col-12 text-center my-5 p-2 pt-5 bg-secondary">
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
                  </div>
                </div>
                <div v-else-if="isLoaded && devices.length > 0" class="table-responsive">
                  <!--                  <div v-if="tableView === 'simple'" class="table-responsive-sh">simple</div>-->
                  <!--                  <div v-else class="table-responsive-sh">advanced</div>-->
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr v-if="tableView === 'simple'" class="text-uppercase">
                        <th class="text-left pl-6" style="min-width: 80px">
                          <span
                            @click="orderDevicesByIsCritical"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isCriticalOrder.isActive,
                              }"
                            >
                              C</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isCriticalOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isCriticalOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 80px">
                          <span
                            @click="orderDevicesByIsProblematic"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isProblematicOrder.isActive,
                              }"
                            >
                              P</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isProblematicOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isProblematicOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          style="min-width: 200px"
                        >
                          <span
                            @click="orderDevicesByLessor"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': lessorOrder.isActive,
                              }"
                            >
                              {{ $t('GENERAL.LESSOR') }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': lessorOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="lessorOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 200px">
                          <span
                            @click="orderDevicesByLocation"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': locationOrder.isActive,
                              }"
                            >
                              {{ $t('GENERAL.LOCATION') }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': locationOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="locationOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 300px">
                          <span
                            @click="orderDevicesByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': nameOrder.isActive,
                              }"
                            >
                              Name & Microlocation</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th
                          style="min-width: 150px"
                          v-if="
                            currentUserRole === 'ROLE_ROOT' ||
                            currentUserRole === 'ROLE_SUPER_ADMIN' ||
                            currentUserRole === 'ROLE_ADMIN'
                          "
                        >
                          <span
                            @click="orderDevicesBySaldo"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': saldoOrder.isActive }"
                            >
                              Saldo</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': saldoOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="saldoOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 150px">Last Pay Coin</th>
                        <th style="min-width: 150px">Last Pay SMS</th>
                        <th style="min-width: 150px">Last Pay Card</th>
                        <th style="min-width: 150px">Last Pay Loyalty</th>
                        <th style="min-width: 150px">Last Empty</th>
                        <th style="min-width: 180px">Responsible person</th>
                        <th style="min-width: 150px" class="text-right pr-6">
                          More Details
                        </th>
                      </tr>
                      <tr v-else class="text-uppercase">
                        <th class="text-left pl-6" style="min-width: 80px">
                          <span
                            @click="orderDevicesByIsCritical"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isCriticalOrder.isActive,
                              }"
                            >
                              C</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isCriticalOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isCriticalOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 80px">
                          <span
                            @click="orderDevicesByIsProblematic"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isProblematicOrder.isActive,
                              }"
                            >
                              P</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isProblematicOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isProblematicOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          style="min-width: 200px"
                        >
                          <span
                            @click="orderDevicesByLessor"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': lessorOrder.isActive,
                              }"
                            >
                              {{ $t('GENERAL.LESSOR') }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': lessorOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="lessorOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 200px">
                          <span
                            @click="orderDevicesByLocation"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': locationOrder.isActive,
                              }"
                            >
                              {{ $t('GENERAL.LOCATION') }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': locationOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="locationOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 300px">
                          <span
                            @click="orderDevicesByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': nameOrder.isActive,
                              }"
                            >
                              Name & Microlocation</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th
                          style="min-width: 150px"
                          v-if="
                            currentUserRole === 'ROLE_ROOT' ||
                            currentUserRole === 'ROLE_SUPER_ADMIN' ||
                            currentUserRole === 'ROLE_ADMIN'
                          "
                        >
                          <span
                            @click="orderDevicesBySaldo"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': saldoOrder.isActive }"
                            >
                              Saldo</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': saldoOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="saldoOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 150px">Last Pay Coin</th>
                        <th style="min-width: 150px">Last Pay SMS</th>
                        <th style="min-width: 150px">Last Pay Card</th>
                        <th style="min-width: 150px">Last Pay Loyalty</th>
                        <th style="min-width: 150px">Last Empty</th>
                        <th style="min-width: 200px">Day Amount Total</th>
                        <th style="min-width: 150px">Day Amount Coin</th>
                        <th style="min-width: 150px">Day Amount SMS</th>
                        <th style="min-width: 150px">Day Amount Card</th>
                        <th style="min-width: 200px">Day Amount Loyalty</th>
                        <th style="min-width: 180px">Responsible person</th>
                        <th style="min-width: 150px" class="text-right pr-6">
                          More Details
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="tableView === 'simple'">
                      <tr v-for="(device, key) in devices" :key="device.id">
                        <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="device.isCritical"
                            class="label label-sm label-danger p-3"
                          ></span>
                          <span
                            v-else
                            class="label label-sm label-success p-3"
                          ></span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="device.isProblematic"
                            class="label label-sm label-danger p-3"
                          ></span>
                          <span
                            v-else
                            class="label label-sm label-success p-3"
                          ></span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            class="font-weight-bolder"
                            >{{ device.lessorName ?? '/' }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span class="font-weight-bolder">{{
                            device.location.title
                          }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                            >{{ device?.name }}</span
                          >
                          <div>
                            <span
                              v-for="tag in device.tags"
                              :key="tag.id"
                              class="label label-sm label-light-primary label-inline mr-2"
                              >{{ tag?.name }}</span
                            >
                          </div>
                        </td>
                        <td
                          v-if="
                            currentUserRole === 'ROLE_ROOT' ||
                            currentUserRole === 'ROLE_SUPER_ADMIN' ||
                            currentUserRole === 'ROLE_ADMIN'
                          "
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            v-if="parseInt(device.saldo) > 0"
                            class="text-dark flex-root font-weight-bold"
                            >{{ parseFloat(device.saldo) | currencyFormat }}
                          </span>
                          <span
                            v-else
                            class="text-dark flex-root font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPayCoin">{{
                            device.eventMeta.lastPayCoin | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPaySms">{{
                            device.eventMeta.lastPaySms | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPayCard">{{
                            device.eventMeta.lastPayCard | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPayRfCard">{{
                            device.eventMeta.lastPayRfCard | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastEmpty">{{
                            device.eventMeta.lastEmpty | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.location.responsibleWorker">{{
                            device.location.responsibleWorker
                          }}</span>
                        </td>
                        <td
                          class="text-right pr-6"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            @click="
                              getDeviceMoreInfo(
                                device.logger.imei,
                                key,
                                device.id
                              )
                            "
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                            v-b-tooltip.hover.top="$t('COMPANIES.SHOW_TRANSACTIONS')"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Media/Airplay.svg"
                              />
                            </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr v-for="(device, key) in devices" :key="device.id">
                        <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="device.isCritical"
                            class="label label-sm label-danger p-3"
                          ></span>
                          <span
                            v-else
                            class="label label-sm label-success p-3"
                          ></span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="device.isProblematic"
                            class="label label-sm label-danger p-3"
                          ></span>
                          <span
                            v-else
                            class="label label-sm label-success p-3"
                          ></span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            class="font-weight-bolder"
                            >{{ device.lessorName ?? '/' }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span class="font-weight-bolder">{{
                            device.location.title
                          }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                            >{{ device?.name }}</span
                          >
                          <div>
                            <span
                              v-for="tag in device.tags"
                              :key="tag.id"
                              class="label label-sm label-light-primary label-inline mr-2"
                              >{{ tag?.name }}</span
                            >
                          </div>
                        </td>
                        <td
                          v-if="
                            currentUserRole === 'ROLE_ROOT' ||
                            currentUserRole === 'ROLE_SUPER_ADMIN' ||
                            currentUserRole === 'ROLE_ADMIN'
                          "
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            v-if="parseInt(device.saldo) > 0"
                            class="text-dark flex-root font-weight-bold"
                            >{{ parseFloat(device.saldo) | currencyFormat }}
                          </span>
                          <span
                            v-else
                            class="text-dark flex-root font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPayCoin">{{
                            device.eventMeta.lastPayCoin | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPaySms">{{
                            device.eventMeta.lastPaySms | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPayCard">{{
                            device.eventMeta.lastPayCard | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastPayRfCard">{{
                            device.eventMeta.lastPayRfCard | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.eventMeta.lastEmpty">{{
                            device.eventMeta.lastEmpty | formatDateTime
                          }}</span>
                          <span v-else>&#8734;</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="
                              parseInt(device.dailySaldo.coin) > 0 ||
                              parseInt(device.dailySaldo.sms) > 0 ||
                              parseInt(device.dailySaldo.card) > 0 ||
                              parseInt(device.dailySaldo.rfCard) > 0
                            "
                            class="text-dark flex-root font-weight-bold"
                            >{{
                              (parseFloat(device.dailySaldo.coin) +
                                parseFloat(device.dailySaldo.sms) +
                                parseFloat(device.dailySaldo.card ?? 0) +
                                parseFloat(device.dailySaldo.rfCard))
                                | currencyFormat
                            }}
                          </span>
                          <span
                            v-else
                            class="text-dark flex-root font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="parseInt(device.dailySaldo.coin) > 0"
                            class="text-dark flex-root font-weight-bold"
                            >{{
                              parseFloat(device.dailySaldo.coin)
                                | currencyFormat
                            }}
                          </span>
                          <span
                            v-else
                            class="text-dark flex-root font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="parseInt(device.dailySaldo.sms) > 0"
                            class="text-dark flex-root font-weight-bold"
                            >{{
                              parseFloat(device.dailySaldo.sms) | currencyFormat
                            }}
                          </span>
                          <span
                            v-else
                            class="text-dark flex-root font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="parseInt(device.dailySaldo.card) > 0"
                            class="text-dark flex-root font-weight-bold"
                            >{{
                              parseFloat(device.dailySaldo.card)
                                | currencyFormat
                            }}
                          </span>
                          <span
                            v-else
                            class="text-dark flex-root font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="parseInt(device.dailySaldo.rfCard) > 0"
                            class="text-dark flex-root font-weight-bold"
                            >{{
                              parseFloat(device.dailySaldo.rfCard)
                                | currencyFormat
                            }}
                          </span>
                          <span
                            v-else
                            class="text-dark flex-root font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="device.location.responsibleWorker">{{
                            device.location.responsibleWorker
                          }}</span>
                        </td>
                        <td
                          class="text-right pr-6"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            @click="
                              getDeviceMoreInfo(
                                device.logger.imei,
                                key,
                                device.id
                              )
                            "
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Media/Airplay.svg"
                              />
                            </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else-if="isLoaded && devices.length === 0" class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                  <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
                    <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                      <p class="font-weight-bold" style="font-size: 24px;">No devices.</p>
                      <p style="font-size: 16px;">There are currently no devices.</p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div v-if="isLoaded" class="card-footer py-6">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">Base: </span>
              <span>{{ base | currencyFormat }} <span> </span></span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">PDV: (25%)</span>
              <span>{{ tax | currencyFormat }} <span> </span></span>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Total:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ total | currencyFormat }} <span> </span
              ></span>
            </div>
            <div class="separator separator-dashed my-5"></div>
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Daily Coin:</span
              >
              <span
                v-if="dailySaldo.coin > 0"
                class="font-size-lg font-weight-bolder text-dark"
                >{{ dailySaldo.coin | currencyFormat }} <span> </span
              ></span>
              <span v-else class="font-size-lg font-weight-bolder text-dark"
                >0,00 <span> €</span></span
              >
            </div>
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Daily SMS:</span
              >
              <span
                v-if="dailySaldo.sms > 0"
                class="font-size-lg font-weight-bolder text-dark"
                >{{ dailySaldo.sms | currencyFormat }} <span> </span
              ></span>
              <span v-else class="font-size-lg font-weight-bolder text-dark"
                >0,00 <span> €</span></span
              >
            </div>
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Daily Card:</span
              >
              <span
                v-if="dailySaldo.card > 0"
                class="font-size-lg font-weight-bolder text-dark"
                >{{ dailySaldo.card | currencyFormat }} <span> </span
              ></span>
              <span v-else class="font-size-lg font-weight-bolder text-dark"
                >0,00 <span> €</span></span
              >
            </div>
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Daily Loyalty:</span
              >
              <span
                v-if="dailySaldo.rfCard > 0"
                class="font-size-lg font-weight-bolder text-dark"
                >{{ dailySaldo.rfCard | currencyFormat }} <span> </span
              ></span>
              <span v-else class="font-size-lg font-weight-bolder text-dark"
                >0,00 <span> €</span></span
              >
            </div>
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Daily Total:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ dailySaldo.total | currencyFormat }} <span> </span
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'

export default {
  name: 'DetailedReviewList',
  data() {
    return {
      devices: [],
      currentTime: new Date(),
      base: 0,
      tax: 0,
      total: 0,
      dailySaldo: {
        total: 0,
        coin: 0,
        sms: 0,
        card: 0,
        rfCard: 0,
      },
      isLoaded: false,

      deviceTodayRevenue: {
        deviceName: null,
        value: null,
      },
      isDeviceTodayRevenueLoaded: false,

      // orders
      isCriticalOrder: {
        query: 'asc',
        isActive: false,
      },
      isProblematicOrder: {
        query: 'asc',
        isActive: false,
      },
      lessorOrder: {
        query: 'asc',
        isActive: false,
      },
      locationOrder: {
        query: 'asc',
        isActive: false,
      },
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
      saldoOrder: {
        query: 'asc',
        isActive: false,
      },
      responsibleWorkerOrder: {
        query: 'asc',
        isActive: false,
      },
      tableView: 'simple',
      tableViewButtonText: 'Advanced View',

      moreInfo: {
        device: null,
        dayAmountTotal: 0,
        dayCoinTotal: 0,
        daySmsTotal: 0,
        dayCardTotal: 0,
        dayLoyaltyTotal: 0,
        lastCoinBalanceChange: null,
        lastSmsBalanceChange: null,
        lastLoyaltyBalanceChange: null,
        lastEmpty: null,
      },
      isDeviceMoreInfoLoaded: true,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
    ]),
  },
  mounted() {
    this.$store
      .dispatch(SET_BREADCRUMB, [
        { title: 'Detailed Review', route: '/detailed-review/list' },
      ])
      .then(() => {
        this.getDetailedReview('[name]=asc')
      })
  },
  methods: {
    getDetailedReview(order) {
      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
        ApiService.get(
          `devices?company=${this.currentUserCompany}&new=1&order${order}&select=other,timestamps&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.devices.forEach(({ saldo, dailySaldo }) => {
            this.base += parseFloat(saldo) * 0.8
            this.tax += parseFloat(saldo) - parseFloat(saldo) * 0.8
            this.total += parseFloat(saldo)
            this.dailySaldo.total +=
              parseFloat(dailySaldo.coin) +
              parseFloat(dailySaldo.sms) +
              parseFloat(dailySaldo.card ?? 0) +
              parseFloat(dailySaldo.rfCard)
            this.dailySaldo.coin += parseFloat(dailySaldo.coin)
            this.dailySaldo.sms += parseFloat(dailySaldo.sms)
            this.dailySaldo.card += parseFloat(dailySaldo.card ?? 0)
            this.dailySaldo.rfCard += parseFloat(dailySaldo.rfCard)
          })

          this.isLoaded = true
        })
      } else {
        ApiService.get('devices', `?new=1&order${order}&select=other,timestamps&pagination=false`).then(
          ({ data }) => {
            this.devices = [...data['hydra:member']]
            this.devices.forEach(({ saldo, dailySaldo }) => {
              this.base += parseFloat(saldo) * 0.8
              this.tax += parseFloat(saldo) - parseFloat(saldo) * 0.8
              this.total += parseFloat(saldo)
              this.dailySaldo.total +=
                parseFloat(dailySaldo.coin) +
                parseFloat(dailySaldo.sms) +
                parseFloat(dailySaldo.card ?? 0) +
                parseFloat(dailySaldo.rfCard)
              this.dailySaldo.coin += parseFloat(dailySaldo.coin)
              this.dailySaldo.sms += parseFloat(dailySaldo.sms)
              this.dailySaldo.card += parseFloat(dailySaldo.card ?? 0)
              this.dailySaldo.rfCard += parseFloat(dailySaldo.rfCard)
            })

            this.isLoaded = true
          }
        )
      }
    },
    diffInMinutes(dateLeft, dateRight) {
      if (dateLeft) {
        return differenceInMinutes(new Date(dateLeft), new Date(dateRight))
      }
    },
    refresh() {
      this.devices = []

      this.getDetailedReview()
    },

    // exports
    exportAsExcel() {
      const from = format(this.range.start, 'yyyy-MM-dd')
      const end = format(this.range.end, 'yyyy-MM-dd')

      window
        .open(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/company-locations-revenue/${this.currentUserCompanyOib}/${this.locations[0]['id']}/${from}/${end}`,
          '_blank'
        )
        .focus()
    },
    exportAsPDF() {
      const from = format(this.range.start, 'yyyy-MM-dd')
      const end = format(this.range.end, 'yyyy-MM-dd')

      window
        .open(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/pdf/company-locations-revenue/${this.currentUserCompanyOib}/${this.locations[0]['id']}/${from}/${end}`,
          '_blank'
        )
        .focus()
    },

    changeTableView(currentView) {
      if (currentView === 'simple') {
        this.tableView = 'advanced'
        this.tableViewButtonText = 'Simple View'
      } else {
        this.tableView = 'simple'
        this.tableViewButtonText = 'Advanced View'
      }
    },

    // orders
    orderDevicesBySaldo() {
      this.saldoOrder.isActive = true

      if (this.saldoOrder.query === 'asc') this.saldoOrder.query = 'desc'
      else this.saldoOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      this.getDetailedReview(`[saldo]=${this.saldoOrder.query}`)
    },
    orderDevicesByIsCritical() {
      this.isCriticalOrder.isActive = true

      if (this.isCriticalOrder.query === 'asc')
        this.isCriticalOrder.query = 'desc'
      else this.isCriticalOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
        this.getDetailedReview(`[isCritical]=${this.isCriticalOrder.query}`)
      }
    },
    orderDevicesByIsProblematic() {
      this.isProblematicOrder.isActive = true

      if (this.isProblematicOrder.query === 'asc')
        this.isProblematicOrder.query = 'desc'
      else this.isProblematicOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
        this.getDetailedReview(`[isProblematic]=${this.isProblematicOrder.query}`)
      }
    },
    orderDevicesByLessor() {
      this.lessorOrder.isActive = true

      if (this.lessorOrder.query === 'asc') this.lessorOrder.query = 'desc'
      else this.lessorOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
       this.getDetailedReview(`[lessor]=${this.lessorOrder.query}`)
      }
    },
    orderDevicesByLocation() {
      this.locationOrder.isActive = true

      if (this.locationOrder.query === 'asc') this.locationOrder.query = 'desc'
      else this.locationOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
       this.getDetailedReview(`[location.title]=${this.locationOrder.query}`)
      }
    },
    orderDevicesByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

     this.getDetailedReview(`[name]=${this.nameOrder.query}`)
    },
    orderDevicesByResponsibleWorker() {
      this.responsibleWorkerOrder.isActive = true

      if (this.responsibleWorkerOrder.query === 'asc')
        this.responsibleWorkerOrder.query = 'desc'
      else this.responsibleWorkerOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      this.getDetailedReview(`[location.responsibleWorker]=${this.responsibleWorkerOrder.query}`)
    },

    getDeviceMoreInfo(imei, key, deviceId) {
      ;(this.moreInfo.device = this.devices[key]),
        (this.moreInfo.dayAmountTotal = 0),
        (this.moreInfo.dayCoinTotal = 0),
        (this.moreInfo.daySmsTotal = 0),
        (this.moreInfo.dayLoyaltyTotal = 0),
        (this.moreInfo.lastCoinBalanceChange = null),
        (this.moreInfo.lastSmsBalanceChange = null),
        (this.moreInfo.lastLoyaltyBalanceChange = null),
        (this.moreInfo.lastEmpty = null)

      const todayStart = `${format(new Date(), "yyyy-MM-dd'T'HH:mm").slice(
        0,
        10
      )}T00:00`
      const todayCurrent = format(new Date(), "yyyy-MM-dd'T'HH:mm")

      ApiService.get(
        'transactions',
        `?imei=${imei}&createdAt[after]=${todayStart}&createdAt[before]=${todayCurrent}&pagination=false`
      ).then(({ data }) => {
        data['hydra:member'].forEach((transaction) => {
          // day total
          this.moreInfo.dayAmountTotal += parseFloat(transaction.amount)

          // pay coin
          if (transaction.paymentType === 'pay_coin')
            this.moreInfo.dayCoinTotal += parseFloat(transaction.amount)

          // pay sms
          if (transaction.paymentType === 'pay_sms')
            this.moreInfo.daySmsTotal += parseFloat(transaction.amount)

          // pay loyalty
          if (transaction.paymentType === 'pay_rfcard')
            this.moreInfo.dayLoyaltyTotal += parseFloat(transaction.amount)

          // pay card
          if (transaction.paymentType === 'pay_card') {
            this.moreInfo.dayCardTotal += parseFloat(transaction.amount)
          }
        })

        ApiService.get(
          'transactions',
          `?imei=${imei}&paymentType=pay_coin&order[createdAt]=desc&pagination=false`
        ).then(({ data }) => {
          if (data['hydra:member'].length !== 0) {
            this.moreInfo.lastCoinBalanceChange =
              data['hydra:member'][0].createdAt
          }

          ApiService.get(
            'transactions',
            `?imei=${imei}&paymentType=pay_sms&order[createdAt]=desc&pagination=false`
          ).then(({ data }) => {
            if (data['hydra:member'].length !== 0) {
              this.moreInfo.lastSmsBalanceChange =
                data['hydra:member'][0].createdAt
            }

            ApiService.get(
              'transactions',
              `?imei=${imei}&paymentType=pay_rfcard&order[createdAt]=desc&pagination=false`
            ).then(({ data }) => {
              if (data['hydra:member'].length !== 0) {
                this.moreInfo.lastLoyaltyBalanceChange =
                  data['hydra:member'][0].createdAt
              }

              ApiService.get(
                'deposits',
                `?device=${deviceId}&order[createdAt]=desc&pagination=false`
              ).then(({ data }) => {
                if (data['hydra:member'].length !== 0) {
                  this.moreInfo.lastEmpty = data['hydra:member'][0].createdAt
                }

                this.isDeviceMoreInfoLoaded = true
                this.$root.$emit('bv::show::modal', 'device_more_info')
              })
            })
          })
        })
      })
    },
  },
}
</script>

<style lang="scss">
.table-responsive {
  height: 45vh;

  thead {
    position: sticky;
    top: 0;
  }
}
</style>
